import React from 'react'
import '../navbar.css'

export const EnviromentText = () => {
  let path = window.location.href
  if (
    !path.includes('qa') &&
    !path.includes('test') &&
    !path.includes('localhost')
  ) {
    return <></>
  }
  return (
    <span className="enviromentText">{`Ambiente de ${
      path.includes('qa') ? 'QA' : path.includes('test') ? 'TEST' : 'DESARROLLO'
    }`}</span>
  )
}

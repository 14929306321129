import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import logo from '../../../assets/icons/Macro.png'
import { X } from 'react-feather'
import CopyToClipboard from 'react-copy-to-clipboard'
import handleToast from '../../../components/toaster'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
export const Macro = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { pageWidth } = useSelector((state) => state.pageWidthReducer)
  const history = useHistory()
  const closeBtn = (
    <X
      className="closeModalButton"
      onClick={() => {
        setIsOpen(false)
      }}
    />
  )

  const shareHandler = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Pago con Banco Macro',
          text: 'Utilizá este link para pagar con el Banco Macro',
          url: window.location.href.includes('new')
            ? process.env.REACT_APP_MACRO_PAGE_PROD
            : process.env.REACT_APP_MACRO_PAGE,
        })
        handleToast('success', 'Contenido compartido exitosamente')
      } catch (error) {
        console.error('Error al compartir:', error)
      }
    } else {
      handleToast(
        'updating',
        'Compartir no es soportado en este navegador.',
        4000
      )
    }
  }

  return (
    <>
      <div className="text-white position-relative">
        <p
          className="py-2 my-2 my-1 cursor-pointer scale disabled sidebarItem"
          onClick={() => setIsOpen(true)}
          id="Macro"
        >
          Banco Macro
        </p>
      </div>
      <Modal
        size="lg"
        style={{ top: '100px' }}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(!isOpen)
        }}
      >
        <ModalHeader close={closeBtn} />
        <ModalBody>
          <div className="container pt-2 w-100">
            <div className="d-flex justify-content-center">
              <img
                className="mt-3"
                src={logo}
                style={{ minWidth: '120px', maxWidth: '500px' }}
                alt="Logo Banco Macro"
                draggable={false}
              />
            </div>
            <p className="text-center fw-bold fs-5 mt-5">
              Recuerde que este pago se realiza en una página externa, solicite
              el comprobante de pago al paciente en caso de ser necesario.
            </p>
          </div>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-between">
          <CopyToClipboard
            text={
              window.location.href.includes('new')
                ? process.env.REACT_APP_MACRO_PAGE_PROD
                : process.env.REACT_APP_MACRO_PAGE
            }
            onCopy={() => {
              handleToast(
                'success',
                'Se ha copiado el link para compartir.',
                5000
              )
              setIsOpen(false)
            }}
          >
            <div className="btn btn-primary">Copiar Link</div>
          </CopyToClipboard>
          <div
            className="btn btn-primary"
            onClick={() => history.push('/pagos/macro')}
          >
            Abrir Link
          </div>
          {pageWidth === 'mobile' && (
            <div className="btn btn-primary" onClick={shareHandler}>
              Compartir Link
            </div>
          )}
        </ModalFooter>
      </Modal>
    </>
  )
}

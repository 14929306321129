import React from 'react'
import './animated-hamburger.css'
import PropTypes from 'prop-types'

const AnimatedHamburger = ({ active }) => {
  return (
    <>
      <svg
        className={`ham hamRotate180 ham5 text-primary ${!active && 'active1'}`}
        viewBox="0 0 100 100"
        width="40"
      >
        <path className="line" d="m 30,33 h 40" />
        <path className="line" d="m 70,50 h -40" />
        <path className="line" d="m 30,67 h 40" />
      </svg>
    </>
  )
}

export default AnimatedHamburger

AnimatedHamburger.propTypes = {
  active: PropTypes.bool.isRequired,
}

import React from 'react'
import { BBVA, Galicia, Macro, Santander, Wibond } from './Modals'
export const BankModal = ({ type }) => {
  const showContent = () => {
    switch (type) {
      case 'Wibond':
        return <></>
        return <Wibond />
      case 'Banco Macro':
        return <></>
        return <Macro />
      case 'BBVA Banco Frances':
        return <BBVA />
      case 'Banco Galicia':
        return <Galicia />
      case 'Banco Santander':
        return <Santander />
      default:
        return
    }
  }
  return <>{showContent()}</>
}

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import SidebarDropdownItem from './atoms/SidebarDropdownItem'
import SidebarItem from './atoms/SidebarItem'
import '../sidebar.css'
import '../../../pages/Admin/Procesos/components/ProposalItem/styles.css'

const OpenSidebarItems = ({ item }) => {
  const [isLinkActive, setIsLinkActive] = useState(false)
  const path = window.location.pathname
  useEffect(() => {
    setIsLinkActive(false)
    if (item.subItems) {
      item.subItems.map((subItem) => {
        let checkPath = path.includes(subItem.link)
        if (checkPath) return setIsLinkActive(true)
        return null
      })
    }
    if (path.includes(item?.link)) return setIsLinkActive(true)
  }, [item.link, item.subItems, path])

  if (item.subItems)
    return <SidebarDropdownItem item={item} isLinkActive={isLinkActive} />
  return <SidebarItem item={item} isLinkActive={isLinkActive} />
}

export default OpenSidebarItems

OpenSidebarItems.propTypes = {
  item: PropTypes.object.isRequired,
}

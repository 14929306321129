import React, { useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import logo from '../../../assets/images/bbva.png'
import qr from '../../../assets/images/qr-bbva.png'
export const BBVA = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className="text-white position-relative">
        <p
          className="py-2 my-2 my-1 cursor-pointer scale disabled sidebarItem"
          onClick={() => setIsOpen(true)}
          id="BBVA"
        >
          BBVA Banco Frances
        </p>
      </div>
      <Modal size="lg" isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
        <ModalBody>
          <div className="d-flex flex-column align-items-center">
            <img alt="LOGO" src={logo} style={{ maxWidth: '400px' }} />
            <hr className="w-100" />
            <img alt="QR" src={qr} style={{ maxWidth: '400px' }} />
          </div>
        </ModalBody>
        <ModalFooter className="fst-italic disabled fs-7 pe-4">
          * Escanear este QR con MODO o Aplicacion Bancaria
        </ModalFooter>
      </Modal>
    </>
  )
}

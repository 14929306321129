let initialState = {
    usersList:[],
    loading:true,
    profilesData:{}
  }
  
  const usersReducer = (state = initialState, action ) => {
    switch (action.type){
      case "GET_USER_LIST":
        let d = action.payload
        return {
          ...state,
            usersList: d.usersList,
            loading:false
        }

      case "SET_PROFILE_DATA":
        return{
          ...state,
          profilesData: action.payload
        }

      default:
        return state
    }
  }
  
  export default usersReducer;
export function getModalArray(onboardingData) {
  let modalArray = []
  if (onboardingData.first_login) {
    modalArray.push({ key: 'first_login', value: onboardingData.first_login })
  }
  if (onboardingData.nps) {
    modalArray.push({ key: 'nps', value: onboardingData.nps })
  }
  if (onboardingData.complete_offices) {
    modalArray.push({
      key: 'complete_offices',
      value: onboardingData.complete_offices,
    })
  }
  if (
    onboardingData.legals.signed_terms_conditions ||
    onboardingData.legals.signed_privacy_policy
  ) {
    modalArray.push({ key: 'legals', value: true })
  }

  return modalArray
}

export function handleModalDispatch(modalArray, dispatch) {
  if (modalArray.length) return dispatch({ type: 'LOGIN_MODAL', payload: true })
  return dispatch({ type: 'LOGIN_MODAL', payload: false })
}

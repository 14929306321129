import React, { memo } from 'react'
import { Route, Switch } from 'react-router'
import { PrivateRoutes } from './Private/PrivateRoutes'
import Auth from './Public/Auth'
import Layout from '../Layout/Layout'
import { BrowserRouter as Router } from 'react-router-dom'
const AppRouter = () => {
  return (
    <Router>
      <Switch>
        <Route path="/app">
          <Layout>
            <PrivateRoutes />
          </Layout>
        </Route>
        <Route path="/">
          <Auth />
        </Route>
      </Switch>
    </Router>
  )
}

export default memo(AppRouter)

const initialState = {
  openState: false,
}

export const loginModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'LOGIN_MODAL':
      return {
        openState: action.payload || initialState.openState,
      }

    default:
      return state
  }
}

import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../../styles/styles.module.css'
import kslogo from '../../../../assets/images/ksloader.png'

export const Legal = ({ data, handleCloseModal }) => {
  const { signed_privacy_policy, signed_terms_conditions } = data
  const getTextToShown = () => {
    if (signed_terms_conditions && signed_privacy_policy)
      return 'los términos, condiciones y politicas de privacidad'
    if (signed_terms_conditions) return 'los términos y condiciones'
    if (signed_privacy_policy) return 'las politicas de privacidad'
  }
  return (
    <div>
      <div className="d-flex justify-content-center mt-5">
        <img src={kslogo} width={150} alt="ks-logo" />
      </div>

      <div className="p-4">
        <div className="d-flex flex-column align-items-center">
          <p className={styles.title}>
            ¡No se olvide de firmar los documentos!
          </p>
          <p className={styles.text}>
            Todavía esta pendiente mostrarse de acuerdo con {getTextToShown()}{' '}
            de KeepSmiling.
          </p>
          <p className={styles.icon}>😥</p>
          <Link
            className={styles.button}
            to="/app/legal"
            onClick={() => handleCloseModal(true)}
          >
            Ir a firmar
          </Link>
        </div>
      </div>
    </div>
  )
}

import { combineReducers } from 'redux'
import userReducer from './reducers/userReducer'
import usersReducer from './reducers/usersReducer'
import { pageWidthReducer } from './reducers/pageWidthReducer'
import { loginModalReducer } from './reducers/loginModalReducer'
import sidebarReducer from './reducers/sidebarReducer'
import formOnlineReducer from './reducers/formOnlineReducer'
import { odTreatments } from './reducers/odTreatmentsReducer'
import { notificationsDropdownReducer } from './reducers/notificationsDropdownReducer'

const reducers = combineReducers({
  userReducer,
  usersReducer,
  sidebarReducer,
  formOnlineReducer,
  odTreatments,
  notificationsDropdownReducer,
  pageWidthReducer,
  loginModalReducer,
})

export default reducers

import React from 'react'
import styles from '../styles/styles.module.css'

const Steps = ({ step, setStep }) => {
  return (
    <div className={styles.stepsFather}>
      <div
        className={step === 1 ? styles.currentStep : styles.step}
        onClick={() => setStep(1)}
      />
      <div
        className={step === 2 ? styles.currentStep : styles.step}
        onClick={() => setStep(2)}
      />
      <div
        className={step === 3 ? styles.currentStep : styles.step}
        onClick={() => setStep(3)}
      />
      <div
        className={step === 4 ? styles.currentStep : styles.step}
        onClick={() => setStep(4)}
      />
      <div
        className={step === 5 ? styles.currentStep : styles.step}
        onClick={() => setStep(5)}
      />
    </div>
  )
}

export default Steps

const adminSidebar = [
  {
    logo: 'home',
    title: 'Home',
    active: false,
    link: '/admin/home',
  },
  {
    logo: 'income',
    title: 'Ingresos',
    active: true,
    link: '/ingresos',
  },
  {
    logo: 'treatment',
    title: 'Tratamientos',
    active: true,
    link: '/tratamientos',
  },
  {
    logo: 'patient',
    title: 'Pacientes',
    active: true,
    link: '/pacientes',
  },
  {
    logo: 'dentist',
    title: 'Odontólogos',
    active: true,
    link: '/odontologos',
  },
  {
    logo: 'user',
    title: 'Entidades',
    active: true,
    link: '/entidades',
  },
  {
    logo: 'process',
    title: 'Procesos',
    active: true,
    link: null,
    subItems: [
      {
        title: 'Escaneo Original',
        link: '/app/procesos/escaneo',
        active: true,
      },
      {
        title: 'Movimiento',
        link: '/app/procesos/movimiento',
        active: true,
      },
      {
        title: 'Propuesta de Tratamiento',
        link: '/app/procesos/propuesta',
        active: true,
      },
      {
        title: 'Control de Calidad',
        link: '/app/procesos/controlCalidad',
        active: true,
      },
      {
        title: 'Despacho',
        link: '/app/procesos/despacho',
        active: true,
      },
      {
        title: 'Validación de Pagos',
        link: '/app/procesos/validacionPagos',
        active: true,
      },
    ],
  },
  {
    logo: 'pay',
    title: 'SmilePay',
    active: true,
    link: null,
    subItems: [
      {
        title: '¿Qué es SmilePay?',
        link: '/app/pagos/smile-pay-info',
        active: true,
      },
      {
        title: 'Promociones',
        link: '/app/pagos/promociones',
        active: true,
      },
      // {
      //   title: 'Cobranzas',
      //   link: '/app/pagos/cobranzas',
      //   active: false,
      // },
      // {
      //   title: 'Estado de Pago',
      //   link: '/app/pagos/estado',
      //   active: false,
      // },
      // {
      //   title: 'Cuenta Corriente',
      //   link: '/app/pagos/cuentacorriente',
      //   active: false,
      // },
      {
        title: 'Banco Macro',
        link: '/app/pagos/macro',
        active: true,
      },
      {
        title: 'Tabla de Aranceles',
        link: '/app/pagos/lista-precios',
        active: true,
      },
      {
        title: 'Transferencias',
        link: '/app/bancos',
        active: true,
      },
    ],
  },
  {
    logo: 'claim',
    title: 'Reclamos',
    active: true,
    link: '/reclamos',
  },
  // {
  //   logo: 'smile',
  //   title: 'Smile Creator',
  //   active: false,
  //   link: '/smileCreator',
  // },
  {
    logo: 'user',
    title: 'Usuarios',
    active: true,
    link: null,
    subItems: [
      {
        title: 'Administradores',
        link: '/app/usuarios',
        active: true,
      },
      {
        title: 'Asesores',
        link: '/app/admin/asesores',
        active: true,
      },
    ],
  },
  {
    logo: 'referral',
    title: 'Derivaciones',
    active: true,
    link: '/derivaciones',
  },
]

const dentistSidebar = [
  {
    logo: 'home',
    title: 'Home',
    active: true,
    link: '/home',
  },
  // {
  //   logo: 'income',
  //   title: 'Ingresos',
  //   active: true,
  //   link: '/ingresos',
  // },
  {
    logo: 'patient',
    title: 'Mis Pacientes',
    active: true,
    link: '/mis-pacientes',
  },
  {
    logo: 'application',
    title: 'Nueva Solicitud',
    active: true,
    link: '/solicitud',
  },
  // {
  //   logo: 'consult',
  //   title: 'Consultas',
  //   active: true,
  //   link: '/consultas',
  // },
  {
    logo: 'smile',
    title: 'Smile Creator',
    active: true,
    link: '/smileCreator',
  },
  {
    logo: 'pay',
    title: 'SmilePay',
    active: true,
    link: 'null',
    subItems: [
      {
        title: '¿Qué es SmilePay?',
        link: '/app/pagos/smile-pay-info',
        active: true,
      },
      {
        title: 'Tabla de Aranceles',
        link: '/app/pagos/lista-precios',
        active: true,
      },
      // {
      //   title: 'Estado Pagos',
      //   link: '/app/pagos/estado-pagos',
      //   active: false,
      // },
      {
        title: 'Promociones',
        link: '/app/pagos/promociones',
        active: true,
      },

      {
        title: 'Banco Macro',
        active: true,
      },
      {
        title: 'BBVA Banco Frances',
        active: true,
      },
      {
        title: 'Banco Santander',
        active: true,
      },
      {
        title: 'Banco Galicia',
        active: true,
      },

      {
        title: 'Transferencias',
        link: '/app/bancos',
        active: true,
      },
      {
        title: 'Wibond',
      },
    ],
  },
  {
    logo: 'help',
    title: 'Centro de Ayuda',
    active: true,
    link: 'null',
    subItems: [
      {
        title: 'Guia de Ayuda',
        link: 'https://keepsmilinghelp.zendesk.com/hc/es-419',
        active: true,
      },
      {
        title: 'Asesores ',
        link: '/app/asesores',
        active: true,
      },
      {
        logo: 'legal',
        title: 'Legal',
        active: true,
        link: '/app/legal',
      },
    ],
  },
]

const clinicAdmin = [
  {
    logo: 'home',
    title: 'Home',
    active: true,
    link: '/home',
  },
  // {
  //   logo: 'income',
  //   title: 'Ingresos',
  //   active: true,
  //   link: '/ingresos',
  // },
  {
    logo: 'treatment',
    title: 'Tratamientos',
    active: true,
    link: '/tratamientos',
  },
  {
    logo: 'dentist',
    title: 'Odontólogos',
    active: true,
    link: '/odontologos',
  },
]

export { dentistSidebar, adminSidebar, clinicAdmin }

import React from 'react'
import loadingIcon from '../assets/images/kslogoblue.png'
import smileBlue from '../assets/images/ks-smile-blue.svg'

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { error: null, errorInfo: null }
  }
  componentDidCatch(error, errorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo,
    })
  }

  componentWillUnmount() {
    return this.props.children
  }

  render() {
    if (this.state.errorInfo) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-center h-100 fs-1"
          style={{
            width: '100%',
            height: '400px',
            backgroundColor: 'rgba(255, 255, 255, 1)',
          }}
        >
          <img
            src={loadingIcon}
            alt="loading icon"
            style={{ width: '200px', height: 'auto' }}
          />
          <img
            src={smileBlue}
            style={{
              width: '140px',
              height: 'auto',
              rotate: '180deg',
              marginTop: '-30px',
            }}
            alt="Loading..."
          />
          <p className="mt-5 text-primary text-center">
            ¡Oops algo salio mal, vuelve a intentarlo!
          </p>
        </div>
      )
    }
    return this.props.children
  }
}

import React from 'react'
import { Modal } from 'reactstrap'
import { FirstLogin } from './modals/FirstLogin/FirstLogin'
import { useOnboardingModals } from './hooks/useOnboardingModals'
import { SatisfactionSurvey } from './modals/SatisfactionSurvey/SatisfactionSurvey'
import { Legal } from './modals/Legals/Legal'
import { DirectionsReminder } from './modals/Directions/DirectionsReminder'

const CustomModal = ({ onboardingData }) => {
  const { handleCloseModal, modal, openState } =
    useOnboardingModals(onboardingData)

  let modalsComponents = {
    first_login: <FirstLogin handleCloseModal={handleCloseModal} />,
    complete_offices: (
      <DirectionsReminder handleCloseModal={handleCloseModal} />
    ),
    nps: <SatisfactionSurvey />,
    legals: (
      <Legal data={onboardingData.legals} handleCloseModal={handleCloseModal} />
    ),
  }

  return (
    <Modal isOpen={openState} size="lg" toggle={() => handleCloseModal(false)}>
      {!!modal.length && modalsComponents[modal[0].key]}
    </Modal>
  )
}

export default CustomModal

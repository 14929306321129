import { roles } from '../../config'

// eslint-disable-next-line
export default [
  // {
  //   component: () => import('../../../pages/Dentist/Income/IncomeIndex'),
  //   path: '/ingresos',
  //   exact: true,
  //   permission: [roles.ENTITY_ADMIN, roles.BRANCH_ADMIN],
  // },
  {
    component: () => import('../../../pages/Dentist/Solicitud'),
    path: '/solicitud',
    exact: true,
    permission: [roles.DENTIST],
  },

  {
    component: () => import('../../../pages/Dentist/Home/Home'),
    path: '/home',
    exact: true,
    permission: [roles.DENTIST, roles.ENTITY_ADMIN, roles.BRANCH_ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Dentist/Notifications/Notifications'),
    path: '/notificaciones',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () => import('../../../pages/Dentist/Profile/ProfileIndex'),
    path: '/perfil',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () =>
      import('../../../pages/Dentist/Patients/DentistPatientIndex'),
    path: '/mis-pacientes',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () => import('../../../pages/Dentist/Solicitud/Tratamiento'),
    path: '/tratamientos/nuevo',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () => import('../../../pages/Dentist/Solicitud/Etapa'),
    path: '/etapa/nuevo',
    exact: true,
    permission: [roles.DENTIST],
  },
  // {
  //   component: () => import('../../../pages/Dentist/Solicitud/Viabilidad'),
  //   path: '/viabilidad/nuevo',
  //   exact: true,
  //   permission: [roles.DENTIST],
  // },
  {
    component: () =>
      import(
        '../../../pages/Dentist/DentistMedicalRecordStages/MedicalRecordStages'
      ),
    path: '/treatment-stages/:id',
    exact: true,
    permission: [roles.DENTIST, roles.ENTITY_ADMIN, roles.BRANCH_ADMIN],
  },
  {
    component: () =>
      import(
        '../../../pages/Dentist/DentistMedicalRecord/DentistMedicalRecord'
      ),
    path: '/historia-clinica/:id',
    exact: true,
    permission: [roles.DENTIST, roles.ENTITY_ADMIN, roles.BRANCH_ADMIN],
  },
  {
    component: () =>
      import('../../../pages/Dentist/SmileCreator/Benefits/SmileCreator'),
    path: '/smileCreator',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () =>
      import('../../../pages/Dentist/SmileCreator/Points/SmileCreatorDetail'),
    path: '/smileCreator/detalle',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () => import('../../../pages/Dentist/DentistConsult'),
    path: '/consultas',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () => import('../../../pages/Dentist/Legal/Legal'),
    path: '/legal',
    exact: true,
    permission: [roles.DENTIST],
  },
  {
    component: () => import('../../../pages/Dentist/Advisor/AdvisorIndex'),
    path: '/asesores',
    exact: true,
    permission: [roles.DENTIST],
  },
]

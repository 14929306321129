import styles from './button.module.css'
import PropTypes from 'prop-types'
import React from 'react'

export const Button = ({
  styleType,
  type,
  clickHandler,
  text,
  isDisabled,
  style,
}) => {
  function onClickHandler(e) {
    if (isDisabled) return
    clickHandler(e)
  }

  return (
    <button
      disabled={isDisabled}
      type={type}
      className={styles[styleType]}
      onClick={onClickHandler}
      style={style}
    >
      {text}
    </button>
  )
}

Button.defaultProps = {
  isDisabled: false,
  styleType: 'default-small',
  type: 'button',
  clickHandler: () => {},
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  clickHandler: PropTypes.func,
  styleType: PropTypes.oneOf([
    'default-small',
    'default-medium',
    'default-large',
    'danger-small',
    'danger-medium',
    'white-small',
    'white-medium',
    'danger-line-small',
    'danger-line-medium',
    'blue-line-small',
    'blue-line-medium',
    'ghost-small',
    'ghost-medium',
    'green-fixed',
  ]),
}

import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import { startLogin } from '../../redux/actions/user'

export const ChangePassword = (props) => {
  const { error, setError, setView, dispatch } = props
  return (
    <>
      <div className="w-100 mb-5">
        <p className="h1 fw-bold text-primary mx-5">KeepSmiling</p>
      </div>
      {error.error ? (
        <div className="alert text-danger text-center" role="alert">
          {error.message}
        </div>
      ) : null}
      <Formik
        initialValues={{ email: '', password: '' }}
        validate={(values) => {
          const errors = {}
          if (!values.email) {
            errors.email = 'Campo requerido'
          } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          ) {
            errors.email = 'Email inválido'
          }

          if (!values.password) {
            errors.password = 'Campo requerido'
          }

          return errors
        }}
        onSubmit={(values, { setSubmitting }) => {
          localStorage.removeItem('lgt')
          dispatch(startLogin(values.email, values.password))
          setTimeout(() => {
            const token = localStorage.getItem('lgt')
            if (!token) {
              setSubmitting(false)
              return setError({
                error: true,
                message: 'Error en el login, por favor intente nuevamente.',
              })
            }
            setError({ error: false, message: '' })
          }, 2000)
        }}
      >
        {({ isSubmitting, errors }) => (
          <Form className="mx-5">
            <label htmlFor="email">Email</label>
            <Field
              type="email"
              name="email"
              id="email"
              className={`${
                errors.email
                  ? 'form-control border border-1 border-danger rounded-3 mb-1'
                  : 'form-control'
              }`}
            />
            <ErrorMessage
              className="text-danger"
              name="email"
              component="div"
            />
            <label htmlFor="password" className="mt-2">
              Contraseña
            </label>
            <Field
              type="password"
              name="password"
              id="password"
              className={`${
                errors.password
                  ? 'form-control border border-1 border-danger rounded-3 mb-1'
                  : 'form-control'
              }`}
            />
            <ErrorMessage
              className="text-danger"
              name="password"
              component="div"
            />
            <div className="w-100 d-flex justify-content-center mt-3">
              <a
                href="#"
                className="btn text-muted"
                onClick={() => setView('reset_password')}
              >
                ¡Olvidé mi contraseña!
              </a>
            </div>
            <div className="w-100 d-flex justify-content-center mt-5">
              {!isSubmitting && (
                <button
                  disabled={errors.email || errors.password}
                  type="submit"
                  className="btn btn-primary rounded-pill px-5 w-50"
                >
                  Ingresar
                </button>
              )}
              {isSubmitting && (
                <button
                  className="btn btn-primary rounded-pill px-5 w-50"
                  type="button"
                  disabled
                >
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden">Loading...</span>
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

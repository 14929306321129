import React, { useState } from 'react'
import { User, LogOut, ChevronDown } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import Notifications from '../NotificationDropdown/notifications'
import './navbar.css'

// BANDERAS
import ARG from '../../assets/images/Argentina2.png'
import CHI from '../../assets/images/Chile2.png'
import COL from '../../assets/images/Colombia2.png'
import PAR from '../../assets/images/Paraguay2.png'
import PER from '../../assets/images/Peru2.png'
import URU from '../../assets/images/Uruguay2.png'
import MEX from '../../assets/images/Mexico.png'
import AnimatedHamburger from '../AnimatedHamburger/AnimatedHamburger'
import { EnviromentText } from './components/EnviromentText'
import {
  AccountCircleOutlined,
  AdminPanelSettingsOutlined,
  LoginOutlined,
} from '@mui/icons-material'

export default function Navbar() {
  const [dropDown, setDropDown] = useState(false)
  const dispatch = useDispatch()
  const { user, sidebarActive } = useSelector((state) => ({
    user: state.userReducer,
    sidebarActive: state.sidebarReducer.collapsed,
  }))

  function handleLogOut() {
    localStorage.clear()
    window.location.href = '/'
    dispatch({ type: 'LOGOUT' })
  }

  const profileImage = () => {
    const countries = {
      1: ARG,
      2: URU,
      3: PAR,
      4: COL,
      5: CHI,
      6: PER,
      7: MEX,
    }
    return countries[user.countryId]
  }

  return (
    <nav
      className="d-flex align-items-center justify-content-between px-3 py-2 bg-white navbar
        sticky-top z-1000
      "
    >
      <div
        className={`cursor-pointer`}
        onClick={() => {
          dispatch({ type: 'COLLAPSE' })
        }}
      >
        <AnimatedHamburger active={sidebarActive} />
      </div>

      <EnviromentText />
      <div className="d-flex align-items-center">
        {(user.typeId === 1 || user.typeId === 2) && (
          <span className="nav-bell">
            <Notifications />
          </span>
        )}
        <div
          className="position-relative"
          onClick={() => setDropDown(!dropDown)}
        >
          <div className="cursor-pointer text-primary d-flex align-items-center">
            <User size={20} />
            <strong className="ms-2 desktop">
              {user.name + ' ' + user.surname}
            </strong>
            <ChevronDown
              className={`nav-arrow-down ${dropDown && 'nav-up'}`}
              size={20}
            />
          </div>
          {dropDown && (
            <div className="drop-down">
              {user.typeId === 2 && (
                <a className="dropdown-item" href="/app/perfil">
                  <AccountCircleOutlined /> Mi Perfil
                </a>
              )}
              {user.typeId === 1 && (
                <a
                  className="dropdown-item"
                  style={{ cursor: 'pointer' }}
                  href="/app/admin/panel-comercial"
                >
                  <AdminPanelSettingsOutlined /> Panel Administrativo
                </a>
              )}
              <span
                className="dropdown-item"
                style={{ cursor: 'pointer' }}
                onClick={() => handleLogOut()}
              >
                <LoginOutlined /> Cerrar sesión
              </span>
            </div>
          )}
        </div>
        <img
          alt="imagen"
          src={profileImage()}
          className="sidebar-flag desktop"
        />
      </div>
    </nav>
  )
}

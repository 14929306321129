import React from 'react'
import { useSelector } from 'react-redux'
import kslogo from '../../../../assets/images/ksloader.png'
import { allCountrySurveys } from './data/surveyData'

export const SatisfactionSurvey = () => {
  const { countryId } = useSelector((state) => state.userReducer)

  return (
    <>
      <div className="d-flex justify-content-center mt-5">
        <img alt="foto" src={kslogo} style={{ width: '18%' }} />
      </div>
      <p style={{ fontSize: '20px' }} className="fw-bold text-center my-4">
        Encuesta de satisfacción
      </p>
      <div className="p-3">
        <iframe src={allCountrySurveys[countryId]} title="ks survey" />
      </div>
    </>
  )
}

import React from 'react'
import { ToastContainer } from 'react-toastify'
import RoutesPublic from './RoutesPublic'
import { Redirect, Route, Switch } from 'react-router-dom'

export const PublicRoutes = () => {
  return (
    <>
      <ToastContainer
        limit={1}
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
      />
      <Switch>
        {RoutesPublic.map(({ path, exact, component: Component }) => (
          <Route key={path} path={path} exact={exact}>
            <Component />
          </Route>
        ))}
        <Route path="/login">
          <Redirect to="/" />
        </Route>
      </Switch>
    </>
  )
}

import React, { useState, useEffect } from 'react'
import apiClient from '../../utils/backend'
import handleToast from '../../components/toaster'
import { useDispatch } from 'react-redux'
import { SidebarLogo } from './components/SidebarLogo'
import { NewPassword } from './NewPassword'
import { Login } from './Login'
import { PasswordUpdate } from './PasswordUpdate'
import { useParams } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css'

const NewLogin = () => {
  const [formValues, setFormValues] = useState({
    email: '',
    forgotEmail: '',
    password: '',
    password1: '',
    password2: '',
  })

  const { email, forgotEmail, password, password1, password2 } = formValues
  const { pathToken } = useParams()
  const [recover_email, setRecover_email] = useState(null)
  const [view, setView] = useState('login')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState({ error: false, message: '' })
  const [password_match, setPassword_match] = useState(null)
  const [password_format_error, setPassword_format_error] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    const path = window.location.pathname.split('/')

    if (path.indexOf('changePassword') !== -1 && path.length > 2) {
      apiClient
        .get(`/api/helper/checkPasswordTokentoken=${pathToken}`)
        .then((res) => {
          if (res.status === 502) {
            window.location.href = '/'
          } else {
            setLoading(false)
            setRecover_email(res.data)
          }
        })
        .catch((error) => {
          console.log(error)
          const { data } = error.response
          handleToast('error', `Error (${data.code}), ${data.msg}`, 5000)
          window.location.href = '/'
        })
    } else {
      setLoading(false)
    }
  }, [])

  const handleInputChange = (e) => {
    setFormValues({ [e.target.name]: e.target.value })
  }

  const handleRecoverPassword = async (e) => {
    e.preventDefault()

    if (!e.target.elements[0].value) {
      return handleToast('error', '🤔 Por favor, ingrese un correo.')
    }
    try {
      await apiClient.post(
        `/api/forgotPassword/?email=${e.target.elements[0].value}`
      )
      handleToast(
        'success',
        'Le enviamos un email a su casilla de correo para reestablecer la contraseña. 👍'
      )
      setTimeout(() => {
        window.location.reload()
      }, 2000)
    } catch (err) {
      if (err.response.status === 422) {
        return handleToast('error', 'Dirección de correo no existente')
      }

      return handleToast(
        'error',
        '🤔 Hubo un error, por favor, vuelva a intentar.'
      )
    }
  }

  const handlePasswordValidation = () => {
    const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm

    if (password1 !== password2) {
      setPassword_match(false)
    } else {
      setPassword_match(true)
    }

    if (!regex.test(password1) || !regex.test(password2)) {
      setPassword_format_error(true)
    } else {
      setPassword_format_error(false)
    }
  }

  const handleSetPassword = () => {
    if (password1 === password2) {
      apiClient
        .post(
          `/api/changePassword?password=${password1}&email=${recover_email}&token=${pathToken}`
        )
        .then((res) => {})
        .catch((error) => {
          console.log(error)
          const { data } = error.response
          handleToast('error', `Error (${data.code}), ${data.msg}`, 5000)
        })
    }
  }

  const path = window.location.pathname.split('/')

  return (
    <>
      {loading ? (
        <SidebarLogo />
      ) : path.indexOf('changePassword') !== -1 ? (
        path.length > 2 ? (
          <NewPassword
            error={error}
            handlePasswordValidation={handlePasswordValidation}
            handleInputChange={handleInputChange}
            password_match={password_match}
            password_format_error={password_format_error}
            email={email}
            password={password}
            password1={password1}
            password2={password2}
            handleSetPassword={handleSetPassword}
          />
        ) : (
          (window.location.href = '/')
        )
      ) : path.indexOf('passwordUpdate') !== -1 ? (
        <PasswordUpdate />
      ) : (
        /* LOGIN - RECUPERAR CONTRASEÑA */
        <Login
          dispatch={dispatch}
          email={email}
          password={password}
          error={error}
          setError={setError}
          setView={setView}
          handleInputChange={handleInputChange}
          forgotEmail={forgotEmail}
          handleRecoverPassword={handleRecoverPassword}
          view={view}
        />
      )}
    </>
  )
}

export default NewLogin

import React, { useState, useRef } from 'react'
import { encryptString } from 'pluspagos-aes-encryption'
import { Button } from '../../../components/Button/Button'
import apiClient from '../../../utils/backend'
import logo from '../../../assets/icons/Macro.png'
import handleToast from '../../../components/toaster'
import ks from '../../../assets/images/ksloader.png'

const key = window.location.href.includes('new')
  ? process.env.REACT_APP_MACRO_KEY_PROD
  : process.env.REACT_APP_MACRO_KEY

const postURL = window.location.href.includes('new')
  ? process.env.REACT_APP_MACRO_URL_PROD
  : process.env.REACT_APP_MACRO_URL

const success = window.location.href.includes('new')
  ? process.env.REACT_APP_MACRO_PAYMENT_SUCCESS_PROD
  : process.env.REACT_APP_MACRO_PAYMENT_SUCCESS

const canceled = window.location.href.includes('new')
  ? process.env.REACT_APP_MACRO_PAYMENT_CANCELED_PROD
  : process.env.REACT_APP_MACRO_PAYMENT_CANCELED

const comercioId = window.location.href.includes('new')
  ? process.env.REACT_APP_MACRO_COMERCIO_PROD
  : process.env.REACT_APP_MACRO_COMERCIO

const MacroPage = () => {
  const [loading, setLoading] = useState(false)
  const [amount, setAmount] = useState('')
  const [patient, setPatient] = useState('')
  const [error, setError] = useState('')
  const [doctor, setDoctor] = useState('')
  const [type, setType] = useState('')
  const [transaccionComercioId, setTransaccionComercioId] = useState('')
  const form = useRef(null)

  const CALLBACKSUCCESS = encryptString(success, key)

  const CALLBACKCANCEL = encryptString(canceled, key)

  const SUCURSALCOMERCIO = encryptString('', key)

  const submitData = async (e) => {
    e.preventDefault()

    const formData = new FormData(form.current)

    if (
      amount.unhashed.includes('.') ||
      amount.unhashed.includes(',') ||
      isNaN(Number(amount.unhashed))
    )
      return setError('Por favor, escriba un monto válido')

    setLoading(true)
    try {
      const res = await apiClient.post('/api/macropayments', {
        patient,
        type,
        doctor,
        amount: amount.unhashed,
      })
      setType((prev) => `${prev} - ${patient} - Doctor/a ${doctor}`)
      setDoctor((prev) =>
        encryptString(
          `Pago del Paciente: ${patient}, perteneciente a doctor/a: ${prev}, `,
          key
        )
      )

      setTransaccionComercioId(res.data.id)

      form.current.submit()
    } catch (error) {
      handleToast(
        'error',
        'Ocurrio un error con el envío de su formulario, por favor pongase en contacto con el area de sistemas.',
        5000
      )
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <img
          alt="ksLogo"
          src={ks}
          style={{ maxHeight: '48px' }}
          className="mt-3"
          draggable={false}
        />
        <hr className="my-3 w-100" />
      </div>
      <div
        className="container mt-2 mx-auto"
        style={{ maxWidth: '700px', minWidth: '400px' }}
      >
        <div className="d-flex justify-content-center">
          <img
            className="mt-3"
            src={logo}
            style={{ minWidth: '300px', maxWidth: '400px' }}
            draggable={false}
            alt="Logo Banco Macro"
          />
        </div>

        <div
          className="text-center fs-5 fw-bold mt-4"
          style={{ lineHeight: '32px' }}
        >
          ¿Qué desea abonar?
        </div>

        <div className="d-flex flex-column text-white align-items-center justify-content-center">
          <div className="d-flex w-100 justify-content-between gap-1 my-3">
            <button
              className={`btn btn-primary ${
                type === 'Primer pago' && 'active'
              } text-center w-25 px-0`}
              onClick={() => setType('Primer pago')}
            >
              Primer pago
            </button>
            <button
              className={`btn btn-primary ${
                type === 'Segundo pago' && 'active'
              } text-center w-25 px-0`}
              onClick={() => setType('Segundo pago')}
            >
              Segundo pago
            </button>
            <button
              className={`btn btn-primary ${
                type === 'Adelanto' && 'active'
              } text-center w-25 px-0`}
              onClick={() => setType('Adelanto')}
            >
              Adelanto
            </button>
            <button
              className={`btn btn-primary ${
                type === 'Otros conceptos' && 'active'
              } text-center w-25 px-0`}
              onClick={() => setType('Otros conceptos')}
            >
              Otros conceptos
            </button>
          </div>
          <div className="d-flex flex-column w-100 mt-3 justify-content-start text-primary">
            <label htmlFor="inform">
              Indique aquí el nombre completo del paciente
            </label>
            <input
              type="text"
              className="form-control placeholder-center"
              name="inform"
              placeholder="Ej. Juan Perez"
              value={patient}
              onChange={({ target }) => setPatient(target.value)}
            />
            <label htmlFor="amount">
              Indique el monto (Sin puntos, comas o valores decimales)
            </label>
            <input
              className="form-control placeholder-center"
              name="amount"
              type="text"
              onChange={({ target }) => {
                if (error) {
                  setError('')
                }
                setAmount({
                  hashed: encryptString(`${target.value.toString()}00`, key),
                  unhashed: `${target.value.toString()}00`,
                })
              }}
              placeholder="Ej. 120000"
            />
            {error && <span className="text-danger mb-3">{error}</span>}
          </div>
        </div>
        <form
          method="post"
          action={postURL}
          rel="no-referrer"
          className="d-flex align-items-center w-100 flex-column"
          ref={form}
        >
          <input type="hidden" name="CallbackSuccess" value={CALLBACKSUCCESS} />
          <input type="hidden" name="CallbackCancel" value={CALLBACKCANCEL} />
          <input type="hidden" name="Comercio" value={comercioId} />
          <input
            type="hidden"
            name="SucursalComercio"
            value={SUCURSALCOMERCIO}
          />
          <input
            type="hidden"
            name="Monto"
            value={amount.hashed}
            onChange={() => null}
          />
          <input
            className="form-control placeholder-center"
            name="TransaccionComercioId"
            type="hidden"
            value={transaccionComercioId}
          />
          <div className="d-flex flex-column w-100">
            <label htmlFor="Informacion" className="text-primary">
              Coloque aquí nombre y apellido del doctor
            </label>
            <input
              className="form-control placeholder-center"
              name="Informacion"
              type="text"
              placeholder="Ej. Juana Rodriguez"
              value={doctor}
              onChange={({ target }) => setDoctor(target.value)}
            />
          </div>
          <input type="hidden" name="Producto[0]" value={type} />
          <input
            type="hidden"
            name="MontoProducto[0]"
            value={amount.unhashed}
          />
        </form>
        <div className="d-flex justify-content-center mt-5">
          {type && amount?.unhashed && patient?.length > 3 && doctor && (
            <Button
              styleType="default-medium"
              text="Pagar"
              clickHandler={submitData}
              isDisabled={loading}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default MacroPage

let initialState = {
  treatments: [],
  dentistList: [],
  paginator: null,
  loading: true,
}

export const odTreatments = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_OD_TREATMENTS':
      let d = action.payload
      return {
        ...state,
        treatments: d.treatments,
        paginator: d.paginator,
        loading: false,
      }

    case 'GET_DENTIST':
      return {
        ...state,
        dentistList: action.payload,
      }
    default:
      return state
  }
}

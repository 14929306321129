import { toast } from 'react-toastify'

const handleToast = (type, message, time = 2000) => {
  switch (type) {
    case 'success':
      return toast.success(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      })
    case 'error':
      return toast.error(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    case 'updating':
      return toast.info(message, {
        position: 'top-right',
        autoClose: time,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      })
    default:
      break
  }
}

export default handleToast

import React from 'react'
import { Button } from '../../../../Button/Button'
import styles from '../styles/styles.module.css'

const StepTwo = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className={styles.mainContainerStepTwo}>
        <div className={styles.textContainerStepTwo}>
          <p className={styles.titleStepTwo}>Primer paso:</p>
          <p className={styles.textStepTwo}>Actualizar sus datos personales.</p>
          <p className={styles.textStepTwo}>
            Para esto tiene que ingresar al icono de arriba a la derecha e
            ingresar a "Mi Perfil".
          </p>
          <p className={styles.listTitleStepTwo}>Aqui puede completar:</p>
          <div className="d-flex flex-column">
            <div className={styles.listItemStepTwo}>
              <div></div>
              <p>Número de teléfono</p>
            </div>
            <div className={styles.listItemStepTwo}>
              <div></div>
              <p>Datos fiscales</p>
            </div>
            <div className={styles.listItemStepTwo}>
              <div></div>
              <p>Direcciones *</p>
            </div>
            <div className={styles.listItemStepTwo}>
              <div></div>
              <p>Redes sociales</p>
            </div>
          </div>
        </div>
        <div className={styles.videoSquareStepTwo} />
      </div>
      <div className={styles.footerStepTwo}>
        <p className={styles.quoteStepTwo}>
          *Las direcciones son necesarias para poder visualizarse en el
          "Buscador de doctores".
        </p>
        <Button clickHandler={() => setStep(3)} text="Siguiente" />
      </div>
    </div>
  )
}

export default StepTwo

import NewLogin from '../../pages/Login/NewLogin'
import { NewPassword } from '../../pages/Login/NewPassword'
import PaymentSuccess from '../../pages/Shared/PaymentStatus/PaymentSuccess'
import PaymentCanceled from '../../pages/Shared/PaymentStatus/PaymentCanceled'
import MacroPage from '../../pages/Shared/Macro/Macro'
export default [
  {
    component: NewLogin,
    path: '/',
    exact: true,
  },
  {
    component: NewPassword,
    path: '/passwordReset/:pathToken',
    exact: true,
  },
  {
    component: NewLogin,
    path: '/passwordUpdate/:pathToken',
    exact: true,
  },
  {
    component: PaymentSuccess,
    path: '/pagos/pago-exitoso',
    exact: true,
  },
  {
    component: PaymentCanceled,
    path: '/pagos/pago-cancelado',
    exact: true,
  },
  {
    component: MacroPage,
    path: '/pagos/macro',
    exact: true,
  },
]

import React from "react"
import ContentLoader from "react-content-loader"

const Loader = (props) => (
  <ContentLoader 
    speed={2}
    width={500}
    height={300}
    viewBox="0 0 500 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="10" y="100" rx="10" ry="10" width="475" height="40" /> 
    <rect x="10" y="150" rx="10" ry="10" width="475" height="40" /> 
    <rect x="10" y="26" rx="10" ry="10" width="280" height="40" /> 
    <rect x="150" y="250" rx="20" ry="20" width="235" height="40" />
  </ContentLoader>
)

export default Loader
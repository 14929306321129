import React from 'react'
import { Redirect } from 'react-router'
import { isLoggedIn } from '../utils/isLoggedIn'
import { GetAllowedRoutes } from '../utils/getAllowedRoutes'
import dentistRoutes from './Dentist/dentistRoutes'
import adminRoutes from './Admin/adminRoutes'
import sharedRoutes from './Shared/sharedRoutes'
import MapAllowedRoutes from '../MapAllowedRoutes'

export const PrivateRoutes = () => {
  let unificateRoutes = []
  let allowedRoutes = []

  dentistRoutes.map((dentistRoute) => unificateRoutes.push(dentistRoute))
  adminRoutes.map((adminRoute) => unificateRoutes.push(adminRoute))
  sharedRoutes.map((sharedRoute) => unificateRoutes.push(sharedRoute))

  if (isLoggedIn()) allowedRoutes = GetAllowedRoutes(unificateRoutes)
  else return <Redirect to="/" />

  return (
    <>
      <MapAllowedRoutes routes={allowedRoutes} basePath="/app" isAddNotFound />
    </>
  )
}

import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import apiClient from '../../../utils/backend'
import imgPlaceholder from '../../../assets/images/frontalsonriendo.png'
import handleToast from '../../toaster'
const API_URL =
  '/api/notifications?cant_pages=10&page=1&include=treatment.dentists'

const AdminNotificationsList = ({ active, displayRedTag }) => {
  const [state, setState] = useState(null)
  const history = useHistory()

  function openNotificationHandler() {
    if (history.location.pathname === '/app/admin/notificaciones')
      return window.location.reload()
    history.push('/app/admin/notificaciones')
  }

  const displayMotivo = (motivoId) => {
    if (motivoId === 8) return 'Solicitud de ingreso'
    if (motivoId === 9) return 'Nueva documentación'
    if (motivoId === 10) return 'Aprobación de propuesta de Tratamiento'
    if (motivoId === 11) return 'Rechazo de propuesta de Tratamiento'
    if (motivoId === 12) return 'Se ha subido un plan de tratamiento'
  }

  useEffect(() => {
    const getNotificationsAdmin = async () => {
      try {
        const res = await apiClient.get(API_URL)
        setState(res.data.table)
        let pendingNotification = res.data.table.find(
          (element) => !element.read
        )
        if (pendingNotification) return displayRedTag(true)
        else displayRedTag(false)
      } catch (error) {
        console.log(error)
        const { data } = error.response
        handleToast('error', `Error (${data.code}), ${data.msg}`, 5000)
      }
    }

    getNotificationsAdmin()
  }, [window.location.pathname])

  if (!state || state.length === 0)
    return (
      <div className="d-flex">
        <div className="d-flex align-items-center justify-content-center m-auto fw-bold fs-5 text-primary">
          No hay nuevas notificaciones
        </div>
      </div>
    )

  return (
    <div className="px-2">
      {state.map((item, i) => {
        return (
          <div
            key={item.id}
            className="DD-notification-card cursor-pointer"
            onClick={openNotificationHandler}
            data-testid="notificationContainerCard"
          >
            <div className="d-flex align-items-center">
              <div
                className={item.read ? 'DD-circle-green' : 'DD-circle-yellow'}
              ></div>
              <img
                alt="avatar"
                className="DD-avatar"
                src={
                  item?.treatment?.dentists[0]?.user.avatar
                    ? item?.treatment?.dentists[0]?.user.avatar
                    : imgPlaceholder
                }
              />
            </div>
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center justify-content-between">
                <p
                  className="my-0 fs-7 DD-user-name text-truncate"
                  title={
                    item?.treatment?.dentists[0]?.user.name +
                    ' ' +
                    item?.treatment?.dentists[0]?.user.surname
                  }
                >
                  {item?.treatment?.dentists[0]?.user.name +
                    ' ' +
                    item?.treatment?.dentists[0]?.user.surname}
                </p>
                <p style={{ fontSize: 10 }} className="disabled my-0">
                  {moment.utc(item.created_at).format('DD-MM-YYYY')}
                </p>
              </div>
              <div className="my-1 fw-bold">{displayMotivo(item.type)}</div>
              <div className="mt-2 disabled fs-7">{item.detail}</div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default AdminNotificationsList

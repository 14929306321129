/* eslint-disable react-hooks/exhaustive-deps */
import React, { memo, Suspense, useEffect } from 'react'
import { Route, Switch, useRouteMatch, useHistory } from 'react-router-dom'
import Loader from '../components/loader'
import NotFound from '../pages/NotFound'
import { ErrorBoundary } from '../utils/ErrorBoundaries'
import { useSelector } from 'react-redux'

function MapAllowedRoutes({ routes, basePath, isAddNotFound }) {
  const match = useRouteMatch(basePath)
  const { login_token } = useSelector((state) => state.userReducer)
  const history = useHistory()
  useEffect(() => {
    if (!login_token) {
      history.replace('/')
    }
  }, [login_token])

  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        {routes.map((route) => {
          const { path, component: Component, exact } = route
          const LazyComponent = React.lazy(() => Component())
          return (
            <Route key={path} path={`${match.path}${path}`} exact={exact}>
              <ErrorBoundary>
                <LazyComponent />
              </ErrorBoundary>
            </Route>
          )
        })}
        {isAddNotFound && (
          <Route>
            <NotFound />
          </Route>
        )}
      </Switch>
    </Suspense>
  )
}

export default memo(MapAllowedRoutes)

import { roles } from '../../config'

// eslint-disable-next-line
export default [
  {
    component: () => import('../../../pages/BankData'),
    path: '/bancos',
    exact: true,
    permission: [roles.DENTIST, roles.ADMIN],
  },
  {
    component: () => import('../../../pages/PriceList/PriceList'),
    path: '/pagos/lista-precios',
    exact: true,
    permission: [roles.DENTIST, roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Promotions/Promotions'),
    path: '/pagos/promociones',
    exact: true,
    permission: [roles.DENTIST, roles.ADMIN],
  },
  {
    component: () => import('../../../pages/Shared/SmilePay/SmilePayInfo'),
    path: '/pagos/smile-pay-info',
    exact: true,
    permission: [roles.ADMIN, roles.DENTIST],
  },
]

import React from 'react'
import { HelpCircle } from 'react-feather'
import ReactTooltip from 'react-tooltip'
import styles from './passwordTooltip.module.css'
export default function PasswordTooltip({
  size = 22,
  type = 'error',
  place = 'bottom',
  id,
}) {
  return (
    <div className={styles.passwordTooltip}>
      <div data-tip data-for="password_tooltip">
        <HelpCircle size={size} />
      </div>
      <ReactTooltip
        type={type}
        effect="solid"
        place={place}
        id="password_tooltip"
        className={styles.tooltip}
      >
        La contraseña debe de tener un mínimo de 8 caracteres y no debe contener
        caracteres especiales
      </ReactTooltip>
    </div>
  )
}

import React from 'react'
import { Button } from '../../../../Button/Button'
import styles from '../styles/styles.module.css'

const StepThree = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className={styles.mainContainerStepTwo}>
        <div className={styles.textContainerStepTwo}>
          <p className={styles.titleStepTwo}>Segundo paso:</p>
          <p className={styles.textStepTwo}>
            Leer y aceptar los términos y condiciones de la empresa.
          </p>
          <p className={styles.textStepTwo}>
            Para esto tiene que ir hacia el ítem de "Legal" en el sidebar. Aquí
            podrá leer los términos y condiciones y las políticas de privacidad
            de KeepSmiling.
          </p>
          <p className={styles.textStepTwo}>
            Puede aceptar estos documentos haciendo click en "Leí y me muestro
            de acuerdo con los términos y condiciones".
          </p>
        </div>
        <div className={styles.videoSquareStepTwo} />
      </div>
      <div className={styles.footerStep3}>
        <Button text="Siguiente" clickHandler={() => setStep(4)} />
      </div>
    </div>
  )
}

export default StepThree

import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export const CustomLink = ({ children, url }) => {
  const { countryId } = useSelector((state) => state.userReducer)
  // validte if url existe
  if (!url) return children

  // check if internal or external link
  if (url.includes('https://') || url.includes('www.'))
    return (
      <Link to={{ pathname: url }} target="_blank">
        {children}
      </Link>
    )
  else
    return (
      <Link to={`${url}`}>
        <span onClick={() => localStorage.removeItem('lastSearch')}>
          {children}
        </span>
      </Link>
    )
}

CustomLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  url: PropTypes.string,
}

import React from 'react'
import { Link } from 'react-router-dom'
import kslogo from '../../../../assets/images/ksloader.png'
import styles from '../../styles/styles.module.css'

export const DirectionsReminder = ({ handleCloseModal }) => {
  return (
    <div>
      <div className="d-flex justify-content-center mt-5">
        <img src={kslogo} width={150} alt="ks-logo" />
      </div>
      <div className="p-4">
        <div className="d-flex flex-column align-items-center">
          <p className={styles.title}>¡No se olvide de completar sus datos!</p>
          <p className={styles.text}>
            Todavía esta pendiente completar datos de direcciones en su perfil.
          </p>
          <p className={styles.icon}>😥</p>
          <Link
            className={styles.button}
            to="/app/perfil"
            onClick={() => handleCloseModal(true)}
          >
            Ir a completar mi perfil
          </Link>
        </div>
      </div>
    </div>
  )
}

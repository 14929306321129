let initialState = {
  collapsed: true,
}

const sidebarReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'COLLAPSE':
      return {
        collapsed: !state.collapsed,
      }
    default: {
      return state
    }
  }
}

export default sidebarReducer

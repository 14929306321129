import React from 'react'
import { ChevronLeft } from 'react-feather'
import { Button } from '../../components/Button/Button'

export const ResetPassword = (props) => {
  const { setView, handleInputChange, forgotEmail, handleRecoverPassword } =
    props

  return (
    <div style={{ maxWidth: 600 }} className="mx-auto">
      <div className="w-100 mb-5">
        <div href="#" className="mb-2" onClick={() => setView('login')}>
          <small className="text-muted cursor-pointer">
            <ChevronLeft size={14} /> Volver
          </small>
        </div>
        <p className="h1 fw-bold text-primary ">Recuperar contraseña</p>
      </div>

      <form onSubmit={(e) => handleRecoverPassword(e)}>
        <div className="mb-3 ">
          <label htmlFor="exampleFormControlInput1" className="form-label">
            Email o usuario
          </label>
          <input
            type="email"
            name="email"
            onChange={(e) => handleInputChange(e)}
            className="form-control"
            id="exampleFormControlInput1"
            placeholder="ejemplo@keepsmiling.com.ar"
            value={forgotEmail}
          />
        </div>
        <div className="w-100 d-flex justify-content-center mt-5">
          <Button text="Enviar" type="submit" styleType="default-medium" />
        </div>
      </form>
    </div>
  )
}

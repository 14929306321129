import React, { useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'

export const Wibond = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <div className="text-white position-relative">
        <p
          className="py-2 my-2 my-1 cursor-pointer scale disabled sidebarItem"
          onClick={() => setIsOpen(true)}
        >
          Wibond
        </p>
      </div>
      <Modal
        size="lg"
        style={{ top: '150px' }}
        isOpen={isOpen}
        toggle={() => setIsOpen(!isOpen)}
      >
        <ModalBody>
          <div className="container pt-2">
            <p
              className="mt-3 text-black text-center fs-2 fw-bold"
              style={{ letterSpacing: '1.2rem' }}
            >
              WIBOND
            </p>
            <div
              className="text-center fs-5 fw-bold mt-4"
              style={{ lineHeight: '32px' }}
            >
              <span>
                Ofrecé pagos en cuotas con y sin intereses a tus clientes.{' '}
                <br />
                De una manera simple y rápida.
              </span>
            </div>
            <div className="my-3 mt-4 d-flex justify-content-center gap-4">
              <Link
                className="wibondLink"
                to={{
                  pathname:
                    'https://cwmnz04.na1.hs-sales-engage.com/Ctc/LY+23284/cWMNZ04/JkM2-6qcW6N1vHY6lZ3lsW5lzn2M34jYSKW6Dy0Bz7LrL4JW8whyR54KmF07W5g4tV02RvzRNW2GKjLx1pfT20W6XH5gt15SFR_MQQrThcRKK1W8qbfFp7zTKJPW6q6Cv92K-y5bMtgwX_KW5JFW2n1-Tp6ff8fsW2gWWmm4XV3PGW3NVg0s2XSwL9W2s1kNM5kL17ZMgSQHBHF7MyW8qC5_98LPGNsW3g7Gsm19q1W6W1ZDj8J6T7X6vW43TPXc4dgCjLW42nQkZ238Gk2W6mjlyx7XDF06W30HRpx1bd52yf9kbbT404',
                }}
                target="_blank"
                onClick={() => setIsOpen(!isOpen)}
              >
                Quiero saber
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  )
}

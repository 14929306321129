const initialState = {
  pageWidth: window.innerWidth > 1000 ? 'desktop' : 'mobile',
}
export const pageWidthReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_WIDTH':
      return {
        pageWidth: action.payload || initialState.pageWidth,
      }

    default:
      return state
  }
}

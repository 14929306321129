import React, { useState } from 'react'
import kslogo from '../../../../assets/images/ksloader.png'
import Steps from './components/Steps'
import StepOne from './components/StepOne'
import StepTwo from './components/StepTwo'
import StepThree from './components/StepThree'
import StepFour from './components/StepFour'
import StepFive from './components/StepFive'
import styles from './styles/styles.module.css'
import { X } from 'react-feather'

export const FirstLogin = ({ handleCloseModal }) => {
  const [step, setStep] = useState(1)
  return (
    <div>
      {step === 5 && (
        <div className="d-flex justify-content-end mt-3 me-3">
          <X
            className={styles.closeModalButton}
            onClick={() => handleCloseModal(false)}
          />
        </div>
      )}
      <div
        className={`d-flex justify-content-center ${
          step === 5 ? 'mt-2' : 'mt-5'
        }`}
      >
        <img alt="foto" src={kslogo} width={150} />
      </div>
      {step === 1 && <StepOne setStep={setStep} />}
      {step === 2 && <StepTwo setStep={setStep} />}
      {step === 3 && <StepThree setStep={setStep} />}
      {step === 4 && <StepFour setStep={setStep} />}
      {step === 5 && (
        <StepFive setStep={setStep} handleCloseModal={handleCloseModal} />
      )}
      <div className="w-100 d-flex justify-content-center">
        <Steps step={step} setStep={setStep} />
      </div>
    </div>
  )
}

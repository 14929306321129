import React from 'react'
import { Button } from '../../../../Button/Button'
import styles from '../styles/styles.module.css'

const StepFour = ({ setStep }) => {
  return (
    <div className="p-4">
      <div className={styles.mainContainerStepTwo}>
        <div className={styles.textContainerStepTwo}>
          <p className={styles.titleStepTwo}>Tercer paso:</p>
          <p className={styles.textStepTwo}>
            Consulte el material de ayuda de la plataforma para poder conocerla.
          </p>
          <p className={styles.textStepTwo}>
            En el sidebar en el ítem "Como funciona la plataforma" encontrará
            todo el material para poder navegar por el portal de KeepSmiling.
          </p>
          <p className={styles.textStepTwo}>
            Igualmente ante cualquier duda, nuestro equipo siempre estará
            disponible para ayudar.
          </p>
        </div>
        <div className={styles.videoSquareStepTwo}>1</div>
      </div>
      <div className={styles.footerStep3}>
        <Button text="Siguiente" clickHandler={() => setStep(5)} />
      </div>
    </div>
  )
}

export default StepFour

import React from 'react'
import Icons from '../Icons/Icons'
import PropTypes from 'prop-types'
import { CustomLink } from '../../CustomLink/CustomLink'
import { CheckPathAndLink } from '../utilities/CheckPathAndLink'
import '../sidebar.css'
import { useDispatch } from 'react-redux'
const CloseSidebarItems = ({ item }) => {
  const { active, link } = item
  const dispatch = useDispatch()
  if (item.subItems)
    return (
      <div
        className={`mx-auto  d-flex flex-start w-full align-items-center sb-item-padding mb-4 cursor-pointer ${
          item.active ? 'scale' : 'disabled'
        }`}
        onClick={() => dispatch({ type: 'COLLAPSE' })}
      >
        <Icons
          type={item.logo}
          className={`${
            !CheckPathAndLink(`/app${link}`) && 'disabled'
          } sidebarItem`}
        />
      </div>
    )
  return (
    <div
      className={`mx-auto  d-flex flex-start w-full align-items-center sb-item-padding mb-4 cursor-pointer ${
        item.active ? 'scale' : 'disabled'
      }`}
    >
      <CustomLink url={active ? `/app${link}` : ''}>
        <Icons
          type={item.logo}
          className={`${
            !CheckPathAndLink(`/app${link}`) && 'disabled'
          } sidebarItem`}
        />
      </CustomLink>
    </div>
  )
}

export default CloseSidebarItems

CloseSidebarItems.propTypes = {
  item: PropTypes.object.isRequired,
}

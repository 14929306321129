import logo from '../../../assets/images/ks-smile.png'
import React from 'react';
import Loader from './loader';

export const SidebarLogo = () => {
    return (
        <main className="h-100">
            <div className="container-fluid h-100">
            <div className="row h-100 align-middle">
                <div className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left" style={{minHeight: '100%', alignItems: 'center'}}>
                    <div className="px-lg-3" style={{marginTop: '10em'}}>
                    <Loader />
                    </div>
                </div>
                <div className="col-4 bg-primary px-5">
                    <div className="w-75 d-flex flex-column" style={{marginTop: '15rem'}}>
                        <p className="h1 text-white fw-bold mb-3">Somos creadores de Sonrisas</p>
                        <img src={logo} alt="keepsmiling logo" className="img img-fluid w-75" />
                    </div>
                </div>
                </div>
            </div>
        </main>
    )
}

import React from 'react'
import Icons from '../../Icons/Icons'
import PropTypes from 'prop-types'
import { CustomLink } from '../../../CustomLink/CustomLink'
import { CheckPathAndLink } from '../../utilities/CheckPathAndLink'
import '../../sidebar.css'

const SidebarItem = ({ item, isLinkActive }) => {
  const { active, link } = item

  return (
    <div
      className={`position-relative cursor-pointer ${
        item.active ? 'scale' : 'disabled mb-2'
      }`}
    >
      <div
        className={`d-flex flex-start align-items-center px-3 py-2 mb-4 ${
          !isLinkActive && 'disabled'
        } ${CheckPathAndLink(link) && 'disabled'} sidebarItem`}
      >
        <CustomLink url={active ? `/app${link}` : ''}>
          <Icons type={item.logo} />
          <span className="mx-2 text-white">{item.title}</span>
        </CustomLink>
      </div>
      {!item.active && (
        <small className="sb-inactive-text">¡Próximamente!</small>
      )}
    </div>
  )
}

export default SidebarItem
SidebarItem.propTypes = {
  item: PropTypes.object.isRequired,
  isLinkActive: PropTypes.bool.isRequired,
}

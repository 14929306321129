import React from 'react'
import styles from '../styles/styles.module.css'

const StepFive = ({ handleCloseModal }) => {
  return (
    <div className="p-4">
      <div className="d-flex justify-content-end"></div>
      <div className="d-flex flex-column align-items-center mx-auto">
        <p className={styles.title}>¡Somos creadores de sonrisas!</p>
        <p className={styles.text}>
          Completando estos pasos ya esta listo/a para navegar el nuevo portal
          KeepSmiling.
        </p>
        <p className={styles.watch}>😁</p>
        <p
          className={styles.startButton}
          onClick={() => handleCloseModal(false)}
        >
          ¡Listo!
        </p>
      </div>
    </div>
  )
}

export default StepFive

import React from 'react'
import { AlertOctagon } from 'react-feather'
import ks from '../../../assets/images/ksloader.png'
export const PaymentCanceled = () => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <img
          alt="ksLogo"
          src={ks}
          style={{ maxHeight: '48px' }}
          className="mt-3"
          draggable={false}
        />
        <hr className="my-3 w-100" />
      </div>
      <div className="container d-flex align-items-center flex-column">
        <h1 className="text-center my-5 text-primary fw-bold">
          Su pago se ha cancelado correctamente
        </h1>
        <AlertOctagon size={200} color="red" />
        <span className="text-primary mt-5 fs-5">
          Ya puede cerrar esta página...
        </span>
      </div>
    </div>
  )
}

export default PaymentCanceled

let initialState = {
  active: false,
}

export const notificationsDropdownReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_DROPDOWN':
      return {
        active: action.payload,
      }

    default: {
      return state
    }
  }
}

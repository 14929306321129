import axios from 'axios'
import { url } from './url'

const apiClient = axios.create({
  baseURL: url(),
  headers: {
    Authorization: `Bearer ${localStorage.getItem('lgt')}`,
  },
})
apiClient.defaults.headers.post['Content-Type'] = 'application/json'

// Interceptor de respuesta
apiClient.interceptors.response.use(
  (response) => response, // Si la respuesta es exitosa, simplemente se retorna
  (error) => {
    if (error.response && error.response.status === 401) {
      // Si la respuesta es 401, redirigimos al login
      window.location.href = '/'
    }
    return Promise.reject(error) // Retornamos el error para que se maneje en otros lugares si es necesario
  }
)

export default apiClient

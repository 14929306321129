import React from 'react'
import smile from '../assets/images/ks-smile-blue.svg'

export default function NotFound() {
  return (
    <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-middle position-relative">
      <h1 className="fw-bold text-primary text-center m-0">Page not found</h1>
      <img
        src={smile}
        className="mx-auto"
        style={{ transform: 'rotate(180deg)', width: '20%' }}
        alt="notFound"
      />
    </div>
  )
}

import React from 'react'
import Smile from '../../../assets/images/ks-smile.svg'
const SmileIcon = ({ width = 40, height = 40 }) => {
  return (
    <img
      className="mx-auto"
      alt="sidebar_logo"
      strokeWidth="1px"
      src={Smile}
      width={height}
      height={width}
    />
  )
}

export default SmileIcon

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getModalArray, handleModalDispatch } from '../utils'

export const useOnboardingModals = (onboardingData) => {
  const { openState } = useSelector((state) => state.loginModalReducer)
  const dispatch = useDispatch()
  const [modal, setModal] = useState([])
  const handleCloseModal = (redirect = false) => {
    if (redirect) return dispatch({ type: 'LOGIN_MODAL', payload: false })
    const mutatedArray = [...modal]
    mutatedArray.shift()
    setModal(mutatedArray)
    if (!mutatedArray.length) dispatch({ type: 'LOGIN_MODAL', payload: false })
  }

  useEffect(() => {
    const modalArray = getModalArray(onboardingData)
    setModal(modalArray)
    handleModalDispatch(modalArray, dispatch)
  }, [dispatch, onboardingData])

  return { handleCloseModal, modal, openState }
}

import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import apiClient from '../../utils/backend'
import logo from '../../assets/images/ks-smile.png'
import { Button } from '../../components/Button/Button'
import handleToast from '../../components/toaster'
import styles from './loginStyles.module.css'
import PasswordTooltip from '../../components/PasswordTooltip/PasswordTooltip'

export const PasswordUpdate = () => {
  const [state, setState] = useState({
    loading: true,
    recover_email: [],
    token: '',
    password_format_error: false,
    password_match: false,
    password_short: false,
  })

  useEffect(() => {
    const path = window.location.pathname.split('/')
    if (path.indexOf('passwordUpdate') !== -1 && path.length > 2) {
      let token = path[2]
      apiClient
        .get(`/api/helper/checkPasswordToken?token=${token}`)
        .then((res) => {
          setState({
            ...state,
            loading: false,
            recover_email: res.data,
            token: token,
          })
        })
        .catch((error) => {
          console.log(error)
          const { data } = error.response
          handleToast('error', `Error (${data.code}), ${data.msg}`, 5000)
          window.location.href = '/'
        })
    } else {
      setState({ ...state, loading: false })
    }
  }, [])

  const handleInputChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
      password_format_error: false,
      password_match: false,
      password_short: false,
    })
  }

  const handleSetPassword = () => {
    if (state.password1 !== state.password2)
      return setState({ ...state, password_match: true })
    if (
      state.password1?.length < 8 ||
      state.password2?.length < 8 ||
      !state.password1 ||
      !state.password2
    )
      return setState({ ...state, password_short: true })
    if (
      !/^[a-zA-Z0-9]+$/.test(state.password1) &&
      !/^[a-zA-Z0-9]+$/.test(state.password2)
    )
      return setState({ ...state, password_format_error: true })

    return apiClient
      .post(
        `/api/changePassword?password=${state.password1}&email=${state.recover_email}&token=${state.token}`
      )
      .then(() => {
        handleToast('success', 'La contraseña se ha cambiado correctamente')
        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      })
      .catch(() => {
        return handleToast(
          'error',
          '🤔 Hubo un error al crear la nueva contraseña'
        )
      })
  }

  return (
    <main className="h-100">
      <div className="container-fluid h-100">
        <div className="row h-100 align-middle">
          <div
            className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
            style={{ minHeight: '100%', alignItems: 'center' }}
          >
            <div className="px-lg-3" style={{ marginTop: '10em' }}>
              <div className="w-100 mb-5">
                <h1 className="fw-bold text-primary mx-5">Nueva contraseña</h1>
              </div>
              {state.error?.error && (
                <div class="alert text-danger text-center" role="alert">
                  {state.error?.message}
                </div>
              )}
              <form action="">
                <div className={styles.passwordTooltipContainer}>
                  <label htmlFor="password1" className="form-label">
                    Contraseña
                  </label>
                  <input
                    type="password"
                    name="password1"
                    id="password1"
                    onChange={(e) => {
                      handleInputChange(e)
                    }}
                    className="form-control"
                    placeholder="********"
                  />
                  <PasswordTooltip
                    type="info"
                    size="22"
                    id="password_tooltip"
                    place="left"
                  />
                </div>
                <div className="mb-3 mx-5">
                  <label htmlFor="password2" className="form-label">
                    Repita la contraseña
                  </label>
                  <input
                    type="password"
                    name="password2"
                    id="password2"
                    onChange={(e) => {
                      handleInputChange(e)
                    }}
                    className="form-control"
                    placeholder="********"
                  />
                  {state.password_match && (
                    <p className="text-danger">Las contraseñas no coinciden.</p>
                  )}
                  {state.password_short && (
                    <p className="text-danger">
                      La contraseña debe de tener un mínimo de 8 caracteres.
                    </p>
                  )}
                  {state.password_format_error && (
                    <p className="text-danger">
                      La contraseña solo puede contener caracteres
                      alfanuméricos.
                    </p>
                  )}
                </div>
              </form>
              <div className="w-100 d-flex justify-content-center mt-5">
                <button
                  className="btn btn-primary rounded-pill px-5 w-50"
                  onClick={() => handleSetPassword()}
                >
                  Enviar
                </button>
              </div>
            </div>
          </div>
          <div className="col-4 bg-primary px-5">
            <div
              className="w-75 d-flex flex-column"
              style={{ marginTop: '15rem' }}
            >
              <h1 className="text-white fw-bold mb-3">
                Somos creadores de Sonrisas
              </h1>
              <img
                src={logo}
                alt="keepsmiling logo"
                className="img img-fluid w-75"
              />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

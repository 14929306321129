import React from 'react'
import ok from '../../../assets/images/RO-check.png'
import ks from '../../../assets/images/ksloader.png'
export const PaymentSuccess = () => {
  return (
    <div>
      <div className="d-flex flex-column align-items-center">
        <img
          alt="ksLogo"
          src={ks}
          style={{ maxHeight: '48px' }}
          className="mt-3"
          draggable={false}
        />
        <hr className="my-3 w-100" />
      </div>
      <div className="container d-flex align-items-center flex-column">
        <h1 className="text-center my-5 text-primary fw-bold">
          Su pago se ha realizado correctamente
        </h1>
        <img alt="OK LOGO" src={ok} width={300} draggable={false} />
        <span className="text-primary text-center mt-5 fs-5">
          El comprobante sera enviado a su correo electrónico, ya puede cerrar
          esta página...
        </span>
      </div>
    </div>
  )
}

export default PaymentSuccess

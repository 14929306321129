import React, { useEffect, useState } from 'react'
import { Message } from '../../components/Message'
import logo from '../../assets/images/ks-smile.png'
import apiClient from '../../utils/backend'
import handleToast from '../../components/toaster'
import PasswordTooltip from '../../components/PasswordTooltip/PasswordTooltip'
import styles from './loginStyles.module.css'
import { useParams } from 'react-router-dom'
export const NewPassword = (props) => {
  const { error } = props

  const { pathToken } = useParams()
  const [recover_email, setRecover_email] = useState(null)
  const [loading, setLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)
  const [password_match_error, setPassword_match_error] = useState(false)
  const [password_format_error, setPassword_format_error] = useState(false)
  const [password_length_error, setPassword_length_error] = useState(false)
  const [password1, setPassword1] = useState(null)
  const [password2, setPassword2] = useState(null)

  useEffect(() => {
    apiClient
      .get(`/api/helper/checkPasswordToken?token=${pathToken}`)
      .then((res) => {
        if (res.status === 502) {
          window.location.href = '/'
        } else {
          setLoading(false)
          setRecover_email(res.data)
        }
      })
      .catch((error) => {
        console.log(error)
        const { data } = error.response
        handleToast('error', `Error (${data.code}), ${data.msg}`, 5000)
        window.location.href = '/'
      })
  }, [pathToken])

  function cleanUpErrors() {
    setPassword_format_error(false)
    setPassword_length_error(false)
    setPassword_match_error(false)
  }

  const handlePasswordValidation = () => {
    cleanUpErrors()
    if (password1.length < 8 || password2.length < 8)
      return setPassword_length_error(true)
    if (
      !/^[a-zA-Z0-9]+$/.test(password1) &&
      !/^[a-zA-Z0-9]+$/.test(password2)
    ) {
      return setPassword_format_error(true)
    }

    if (password1 !== password2) return setPassword_match_error(true)

    return handleSetPassword()
  }

  const handleSetPassword = () => {
    setButtonLoading(true)
    apiClient
      .post(
        `/api/changePassword?password=${password1}&email=${recover_email}&token=${pathToken}`
      )
      .then(() => {
        handleToast(
          'success',
          'Contraseña modificada con exito. Redirigiendo...'
        )
        setTimeout(() => {
          window.location.href = '/'
        }, 2000)
      })
      .catch(() => {
        handleToast(
          'error',
          'Error al modificar la contraseña, intentelo nuevamente'
        )
        setButtonLoading(false)
      })
  }

  if (loading) {
    return null
  } else {
    return (
      <>
        <main className="h-100">
          <div className="container-fluid h-100">
            <div className="row h-100 align-middle">
              <div
                className="col-12 col-sm-8 col-md-6 mx-auto px-5 align-middle text-left"
                style={{ minHeight: '100%', alignItems: 'center' }}
              >
                <div className="px-lg-3" style={{ marginTop: '10em' }}>
                  <div className="w-100 mb-5">
                    <p className="h2 fw-bold text-primary mx-5">
                      Nueva contraseña
                    </p>
                  </div>
                  {error?.error && (
                    <div className="alert text-danger text-center" role="alert">
                      {error?.message}
                    </div>
                  )}
                  <form action="">
                    <div className={styles.passwordTooltipContainer}>
                      <label htmlFor="password1" className="form-label">
                        Contraseña
                      </label>
                      <input
                        type="password"
                        name="password1"
                        onChange={(e) => {
                          cleanUpErrors()
                          setPassword1(e.target.value)
                        }}
                        className="form-control"
                        id="password1"
                        placeholder="********"
                        value={password1}
                        minLength={8}
                      />
                      <PasswordTooltip
                        type="info"
                        size="22"
                        id="password_tooltip"
                        place="left"
                      />
                    </div>
                    <div className="mb-3 mx-5">
                      <label htmlFor="password2" className="form-label">
                        Repita la contraseña
                      </label>
                      <input
                        type="password"
                        name="password2"
                        onChange={(e) => {
                          cleanUpErrors()
                          setPassword2(e.target.value)
                        }}
                        className="form-control"
                        id="password2"
                        placeholder="********"
                        value={password2}
                        minLength={8}
                      />

                      {password_match_error && (
                        <Message
                          type="text-danger"
                          message="Las contraseñas no coinciden."
                        />
                      )}
                      {password_format_error && (
                        <Message
                          type="text-danger"
                          message="La contraseña solo puede contener caracteres alfanuméricos."
                        />
                      )}
                      {password_length_error && (
                        <Message
                          type="text-danger"
                          message="La contraseña debe de tener un mínimo de 8 caracteres."
                        />
                      )}
                    </div>
                  </form>
                  {buttonLoading && (
                    <div className="w-100 d-flex justify-content-center mt-5">
                      <button
                        className="btn btn-primary rounded-pill px-5 w-50 disabled"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span class="sr-only">Loading...</span>
                      </button>
                    </div>
                  )}

                  {!buttonLoading && (
                    <div className="w-100 d-flex justify-content-center mt-5">
                      <button
                        className="btn btn-primary rounded-pill px-5 w-50"
                        onClick={() => handlePasswordValidation()}
                      >
                        Enviar
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-4 bg-primary px-5">
                <div
                  className="w-75 d-flex flex-column"
                  style={{ marginTop: '15rem' }}
                >
                  <p className="h1 text-white fw-bold mb-3">
                    Somos creadores de Sonrisas
                  </p>
                  <img
                    src={logo}
                    alt="keepsmiling logo"
                    className="img img-fluid w-75"
                  />
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    )
  }
}

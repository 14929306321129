import { useLoadScript } from '@react-google-maps/api'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import returnStoreAndPersistor from './redux/store'
import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/keepdesigning.css'
import './index.css'

import AppRouter from './routes/AppRouter'
import { createTheme, ThemeProvider } from '@mui/material'

const libraries = ['places']
const theme = createTheme({
  palette: {
    primary: {
      main: '#001d57',
      info: '#001d57',
    },
    info: {
      main: '#3498dc',
    },
  },
})

function App() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyA5GePOrq5a4XfoMbjjiLrOBYq4hpH7Cds',
    libraries,
  })
  // googleMapsApiKey: 'AIzaSyBQitDykWXuozqQrmq9C-9-BMnBbZSjR34', dev

  localStorage.setItem('isLoaded', isLoaded)

  if (!isLoaded) return <div></div>

  const { store, persistor } = returnStoreAndPersistor()
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <AppRouter />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  )
}

export default App
